<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn
      class="mr-3"
      elevation="1"
      fab
      small
      @click="
        $vuetify.breakpoint.smAndDown
          ? setDrawer(!drawer)
          : $emit('input', !value)
      "
    >
      <v-icon v-if="value"> mdi-view-quilt </v-icon>

      <v-icon v-else> mdi-dots-vertical </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />

    <!-- <v-radio-group v-model="searchType" row>
      <v-radio label="By NID" :value="true"></v-radio>
      <v-radio label="By Name" :value="false"></v-radio>
    </v-radio-group> -->
    <general-search v-if="showSearch" :searchDelinquent="searchDelinquent" />
    <!-- <v-col>
      <v-text-field
        :label="searchLabel"
        color="grey"
        hide-details
        style="max-width: 300px"
        v-model="nid"
        outlined
        type="number"
        dense
        v-if="showSearch"
      >
      </v-text-field>
    </v-col>
    <v-col>
      <v-text-field
        label="Search By Name"
        color="grey"
        hide-details
        style="max-width: 300px"
        v-model="searchName"
        outlined
        dense
        v-if="showSearch"
      >
      </v-text-field>
    </v-col>
    <v-col v-if="showSearch">
      <v-btn class="mt-n2" elevation="1" fab small @click="search">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-col> -->
    <div class="mx-3" />

    <v-btn class="ml-2" min-width="0" text @click="toDashboard">
      <v-icon>mdi-view-dashboard</v-icon>
    </v-btn>

    <!-- <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ attrs, on }">
        <v-btn
          class="ml-2"
          min-width="0"
          text
          v-bind="attrs"
          v-on="on"
        >
          <v-badge
            color="red"
            overlap
            bordered
          >
            <template v-slot:badge>
              <span>2</span>
            </template>

            <v-icon>mdi-bell</v-icon>
          </v-badge>
        </v-btn>
      </template>

      <v-list
        :tile="false"
        nav
      >
        <div>
          <app-bar-item
            v-for="(n, i) in notifications"
            :key="`item-${i}`"
          >
            <v-list-item-title v-text="n" />
          </app-bar-item>
        </div>
      </v-list>
    </v-menu> -->

    <v-menu offset-y open-on-hover>
      <template v-slot:activator="{ attrs, on }">
        <v-btn text v-bind="attrs" v-on="on">
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list flat nav dense>
        <!-- <template v-for="(p, i) in profile"> -->
        <v-list-item @click="processClick" dense>
          <!-- <v-divider
            v-if="p.divider"
            :key="`divider-${i}`"
            class="mb-0 mt-0 secondary"
          />

          <app-bar-item
            v-else
            :key="`item-${i}`"
            class="success"
            @click="processClick"
          > -->
          <v-list-item-title v-text="logoutUser" />
        </v-list-item>
        <!-- </app-bar-item> -->
        <!-- </template> -->
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
// Components
// import { VHover, VListItem } from 'vuetify/lib'

// Utilities
import { mapState, mapMutations } from 'vuex'

import authenticationService from '@/services/AuthenticationService'
import GeneralSearch from '@/components/GeneralSearch.vue'

export default {
  name: 'DashboardCoreAppBar',
  components: {
    GeneralSearch
    // AppBarItem: {
    //   render (h) {
    //     return h(VHover, {
    //       scopedSlots: {
    //         default: ({ hover }) => {
    //           return h(VListItem, {
    //             attrs: this.$attrs,
    //             class: {
    //               'black--text': !hover,
    //               'white--text secondary elevation-12': hover
    //             },
    //             props: {
    //               activeClass: '',
    //               dark: hover,
    //               link: true,
    //               ...this.$attrs
    //             }
    //           }, this.$slots.default)
    //         }
    //       }
    //     })
    //   }
    // }
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    searchName: null,
    searchType: true,
    notifications: [],
    profile: [
      //  { title: 'Profile' },
      //  { title: 'Settings' },
      { divider: true },
      { title: 'Log out ' }
    ],
    nid: null
  }),

  computed: {
    ...mapState('store', [
      'drawer',
      'showSearch',
      'searchLabel',
      'currentUser'
    ]),
    // avatar: true
    currentRoute () {
      return this.$route.name
    },
    logoutUser () {
      return 'Logout ' + this.currentUser.lastName
    }
  },
  watch: {
    searchType (val) {
      if (val) this.searchName = null
      else this.nid = null
    },
    currentRoute () {
      if (this.currentRoute === 'Delinquent') {
        this.setShowSearch(true)
        this.setSearchLabel('Search by NID')
      } else if (this.currentRoute === 'General Screening') {
        this.setShowSearch(true)
        this.setSearchLabel('Search by NID')
      } else if (this.currentRoute === 'Medical Screening') {
        this.setShowSearch(true)
        this.setSearchLabel('Search by NID')
      } else if (this.currentRoute === 'Request For Approval') {
        this.setShowSearch(true)
        this.setSearchLabel('Search by NID')
      } else if (this.currentRoute === 'Approval') {
        this.setShowSearch(true)
        this.setSearchLabel('Search by NID')
      } else if (this.currentRoute === 'Transfer') {
        this.setShowSearch(true)
        this.setSearchLabel('Search by NID')
      } else if (this.currentRoute === 'Receive') {
        this.setShowSearch(true)
        this.setSearchLabel('Search by NID')
      } else if (this.currentRoute === 'Release') {
        this.setShowSearch(true)
        this.setSearchLabel('Search by NID')
      } else if (this.currentRoute === 'Assessment') {
        this.setShowSearch(false)
        this.setSearchLabel('Search by NID')
      } else {
        this.setShowSearch(false)
      }
    }
  },
  created () {
    this.checkAuth()
  },

  methods: {
    ...mapMutations('store', {
      setDrawer: 'SET_DRAWER',
      setShowSearch: 'SET_SHOW_SEARCH',
      setSearchLabel: 'SET_SEARCH_LABEL',
      setDelinquents: 'SET_DELINQUENTS',
      setSnackbar: 'SET_SNACKBAR',
      setColor: 'SET_COLOR',
      setText: 'SET_TEXT',
      resetState: 'resetState'
    }),
    toDashboard () {
      this.$router.push('/')
    },
    processClick () {
      authenticationService.logout().then((response) => {
        this.$store.commit('SET_AUTHENTICATED', false)
        this.resetState()
        this.$router.replace()
        this.$router.replace('/login')
        // this.$router.push('/login')
      })
    },
    searchDelinquent (obj) {
      // if (this.nid === null) {
      this.$delinquentService
        .fetchAllTransfersByNidOrName({ nid: obj.nid, name: obj.name })
        .then((response) => {
          if (response.numberOfElements > 0) {
            this.setDelinquents(response.content)
            this.nid = null
            this.searchName = null
          } else {
            this.setSnackbar(true)
            this.setColor('orange')
            this.setText('Not found')
            this.nid = null
          }
        })
      // } else {
      //   this.setSnackbar(true)
      //   this.setColor('orange')
      //   this.setText('NID should be atleast 13 digits')
      // }
    },
    checkAuth () {
      setTimeout(() => {
        this.$store.commit('SET_AUTHENTICATED', false)
        this.$router.push('/login')
      }, 10800000)
    },
    search () {
      this.searchDelinquent({ nid: this.nid, name: this.searchName })
      // if (this.currentRoute === 'Delinquent') {
      //   this.searchDelinquent()
      // }
    }
  }
}
</script>
